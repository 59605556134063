import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bebbfa3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quote-request-wr" }
const _hoisted_2 = {
  key: 0,
  class: "quote-request-text"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "icon-wr quote-request-counter" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isDesktop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Packages(s)"))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: require(`@/assets/icons/icon-house-builder.svg`)
        }, null, 8, _hoisted_3)),
    _createElementVNode("div", _hoisted_4, _toDisplayString($setup.requestQuoteCounter), 1),
    ($setup.isDesktop)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: "arrow-rotate",
          src: require(`@/assets/icons/arrow-customize-builder-back.svg`)
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}