import { lightenDarkenColor } from "../lightAndDarkenColor";

export const changePrimaryColor = (color: string): void => {
  const root = document.querySelector(":root") as HTMLElement;
  root.style.setProperty("--primary", color);
  root.style.setProperty("--primary-light", lightenDarkenColor(color, 60));
  root.style.setProperty("--primary-hover", hexToRgba(color, 0.8));
  root.style.setProperty("--checkbox-border-checked", color);
  root.style.setProperty("--slider-connect-bg", color);
  root.style.setProperty(
    "--slider-handle-shadow-active",
    `0.5px 0.5px 2px 1px ${hexToRgba(color, 0.42)}`
  );
  root.style.setProperty("--slider-handle-ring-color", hexToRgba(color, 0.42));
};

export const resetPrimaryColor = (): void => {
  setTimeout(() => {
    const primary = "#8f00ff";
    const root = document.querySelector(":root") as HTMLElement;
    root.style.setProperty("--primary", primary);
    root.style.setProperty("--primary-light", lightenDarkenColor(primary, 70));
    root.style.setProperty("--primary-hover", "#9d1fff");
    root.style.setProperty("--checkbox-border-checked", "#8f4fe1");
    root.style.setProperty("--slider-connect-bg", primary);
    root.style.setProperty(
      "--slider-handle-shadow-active",
      "0.5px 0.5px 2px 1px rgba(143, 79, 255, 0.42)"
    );
    root.style.setProperty(
      "--slider-handle-ring-color",
      "rgba(143, 79, 255, 0.42)"
    );
  });
};

export const hexToRgba = (hex: string, alpha = 1) => {
  const [r, g, b] = (hex.match(/\w\w/g) as RegExpMatchArray).map((x) =>
    parseInt(x, 16)
  );
  return `rgba(${r},${g},${b},${alpha})`;
};
