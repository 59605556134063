export const scrollToNextStep = (top: number, id?: string): void => {
  setTimeout(() => {
    if (id) {
      const element = document.getElementById(id) as HTMLElement;
      if (element) {
        window.scrollTo({ top, behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top, behavior: "smooth" });
    }
  }, 100);
};

export const scrollToNextStepMobile = (id: string): void => {
  setTimeout(() => {
    const element = document.getElementById(id) as HTMLElement;
    if (element) {
      window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
    }
  }, 100);
};
