export enum EventActionName {
  CONVERT_TO_LEAD = "convertToLead",
  LOT_ON_CLICK = "lotOnClick",
  HOUSE_DESIGN_ON_CLICK = "houseDesignOnClick",
}

export enum EventByCategory {
  ON_CLICK = "onClick",
  LEAD_CONVERSION = "leadConversion",
}

export enum EventByAction {
  CLICK = "click",
  SUBMIT = "submit",
}

export enum EventLabelByName {
  CLICK_LOT = "Click lot",
  CONTACT_US = "Contact us",
  CLICK_HOUSE_DESIGN = "Click house design",
  MAKE_ENQUIRY = "Make enquiry",
  REQUEST_INITIAL_HOLD = "Request initial hold",
  REQUEST_BUILDER_HOLD = "Request hold",
}

export interface EventTracking {
  actionsName: EventActionName;
  eventByCategory: EventByCategory;
  eventLabelByName: EventLabelByName;
  eventByAction: EventByAction;
  estateById?: string;
  stageById?: string;
  stageTitle?: string;
  lotById?: string;
  lotTitle?: string;
  houseDesignId?: string;
  houseDesignName?: string;
}
