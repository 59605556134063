import { StatesEnum } from "@/enums/StatesEnum";

export const calculateStateFromPostcode = (postcode: string): StatesEnum => {
  const postalCode = parseInt(postcode, 10);

  const postalCodeRanges = {
    [StatesEnum.NSW]: { min: 2000, max: 2999 },
    [StatesEnum.ACT]: { min: 200, max: 299 },
    [StatesEnum.NT]: { min: 800, max: 999 },
    [StatesEnum.QLD]: { min: 4000, max: 4999 },
    [StatesEnum.SA]: { min: 5000, max: 5999 },
    [StatesEnum.TAS]: { min: 7000, max: 7999 },
    [StatesEnum.VIC]: { min: 3000, max: 3999 },
    [StatesEnum.WA]: { min: 6000, max: 6999 },
  };

  const [state] =
    Object.entries(postalCodeRanges).find(
      ([_, range]) => postalCode >= range.min && postalCode <= range.max
    ) ?? [];

  if (!state) {
    throw new Error("Invalid postcode");
  }

  return state as StatesEnum;
};
