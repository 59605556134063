import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1efcf57b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "steppy" }
const _hoisted_2 = { class: "steppy-item-counter" }
const _hoisted_3 = { class: "step-number" }
const _hoisted_4 = {
  key: 0,
  class: "separator-line"
}
const _hoisted_5 = {
  key: 1,
  class: "current-step-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.tabs, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["steppy-item", { current: $setup.stepIndex === index + 1, success: $setup.stepIndex > index + 1 }]),
        key: item
      }, [
        _createElementVNode("div", _hoisted_2, [
          ($setup.stepIndex > index)
            ? (_openBlock(), _createBlock($setup["CheckMark"], {
                key: 0,
                class: "icon-success"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_3, _toDisplayString(index + 1), 1)
        ]),
        (index < $setup.props.tabs.length - 1 && $setup.stepIndex !== index + 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
        ($setup.stepIndex === index + 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item), 1))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ]))
}