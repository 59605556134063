import { ref, onMounted, onUnmounted, Ref } from "vue";

const MIN_DESKTOP_WIDTH = 992;

export function useIsDesktop(width = MIN_DESKTOP_WIDTH): {
  isDesktop: Ref<boolean>;
} {
  const isDesktop = ref(true);
  const handler = () => {
    isDesktop.value = window.innerWidth >= width;
  };

  onMounted(() => {
    handler();
    window.addEventListener("resize", handler);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", handler);
  });

  return { isDesktop };
}
