import {
  EventActionName,
  EventByAction,
  EventByCategory,
  EventLabelByName,
} from "@/types/EventTracking";
import { eventTracking } from "../googleAnalyticsEventTracking";

export const trackSendInitialHold = (estateById: string): void => {
  eventTracking({
    actionsName: EventActionName.CONVERT_TO_LEAD,
    eventByCategory: EventByCategory.LEAD_CONVERSION,
    eventLabelByName: EventLabelByName.REQUEST_INITIAL_HOLD,
    eventByAction: EventByAction.SUBMIT,
    estateById,
  });
};
