import { EventTracking } from "@/types/EventTracking";
import { useGtag } from "vue-gtag-next";

const { event } = useGtag();
export const eventTracking = (data: EventTracking): void => {
  const {
    actionsName,
    eventByCategory,
    eventLabelByName,
    eventByAction,
    estateById = null,
    stageById = null,
    stageTitle = null,
    lotById = null,
    lotTitle = null,
    houseDesignId = null,
    houseDesignName = null,
  } = data;

  event(actionsName, {
    event_category: eventByCategory,
    event_label: eventLabelByName,
    value: 1,
    event_action: eventByAction,
    estate_id: estateById,
    stage_id: stageById,
    stage_title: stageTitle,
    lot_id: lotById,
    lot_title: lotTitle,
    house_design_id: houseDesignId,
    house_design_name: houseDesignName,
  });
};
