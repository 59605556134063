export enum BuilderStepsIds {
  LOT = "lotStep",
  PREVIEW_BUILDER = "builderPreviewStep",
  BUILDER = "builderStep",
  HOUSE = "houseStep",
  FLOORPLAN = "floorplanStep",
  FACADE = "facadeStep",
  CONFIRMATION = "confirmationStep",
}

export enum BuilderStepsIdsPagination {
  LOT = "lotStep",
  BUILDER = "builderStep",
  HOUSE = "houseStep",
  FACADE = "facadeStep",
  CONFIRMATION = "confirmationStep",
}