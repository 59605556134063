import { Ref } from "vue";

export const startDragging = (
  event : MouseEvent,
  zoom : number,
  isDragging : Ref,
  svgContainerMap : Ref,
  svgStartPosition : Ref,
  svgStartScrollPosition : Ref,
) : void => {
  if(zoom !== 1){
    isDragging.value = true;
    svgStartPosition.value = { x: event.clientX, y: event.clientY};
    svgStartScrollPosition.value = {
      x : svgContainerMap.value.scrollLeft,
      y : svgContainerMap.value.scrollTop,
    };
    svgContainerMap.value.style.cursor = 'grabbing';
  }
}

export const handleDragging = (
  event : MouseEvent,
  isDragging : Ref,
  svgContainerMap : Ref,
  svgStartPosition : Ref,
  svgStartScrollPosition : Ref,
) : void => {
  event.preventDefault();
  if (isDragging.value) {
    const deltaX = event.clientX - svgStartPosition.value.x;
    const deltaY = event.clientY - svgStartPosition.value.y;
    svgContainerMap.value.scrollLeft = svgStartScrollPosition.value.x - deltaX;
    svgContainerMap.value.scrollTop = svgStartScrollPosition.value.y - deltaY;
  }
}

export const stopDragging = (
  zoom : number,
  isDragging : Ref,
  svgContainerMap : Ref,
) : void => {
  if(zoom !== 1){
    svgContainerMap.value.style.cursor = 'grab';
  }
  else{
    svgContainerMap.value.style.cursor = 'default';
  }
  isDragging.value = false;
}
