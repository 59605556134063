const RED_SHIFT = 16;
const GREEN_SHIFT = 8;
const BLUE_SHIFT = 0;

const MAX_COLOR_VALUE = 255;
const MIN_COLOR_VALUE = 0;

// Mask for extracting the blue color component
const COLOR_MASK = 0x0000ff;
// Mask for extracting the green color component
const ALPHA_MASK = 0x00ff;

const PERCENT_TO_RGB = 2.55;

const hexToRgb = (color: string): number => parseInt(color.slice(1), 16);

const formatColorValueToHexColor = (rgbValue: number): string => {
  const result = rgbValue.toString(16).padStart(6, "0");
  return `#${result}`;
};
const clamp = (value: number, min: number, max: number): number =>
  Math.max(min, Math.min(value, max));
export const lightenDarkenColor = (color: string, percent: number): string => {
  const colorValue = hexToRgb(color);
  const amount = Math.round(PERCENT_TO_RGB * percent);

  const r = clamp(
    (colorValue >> RED_SHIFT) + amount,
    MIN_COLOR_VALUE,
    MAX_COLOR_VALUE
  );

  const g = clamp(
    ((colorValue >> GREEN_SHIFT) & ALPHA_MASK) + amount,
    MIN_COLOR_VALUE,
    MAX_COLOR_VALUE
  );

  const b = clamp(
    (colorValue & COLOR_MASK) + amount,
    MIN_COLOR_VALUE,
    MAX_COLOR_VALUE
  );

  const rgbValue = (r << RED_SHIFT) | (g << GREEN_SHIFT) | (b << BLUE_SHIFT);

  return formatColorValueToHexColor(rgbValue);
};
