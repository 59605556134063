import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/icon-close-dark.svg'


const _withScopeId = n => (_pushScopeId("data-v-188552f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image-modal-overlay"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "buttons-zoom-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["scroll-wrapper", { 'img-container': $setup.props.zoom !== 1 }]),
          ref: "svgContainerMap",
          onMousedown: $setup.startDrag,
          onMousemove: $setup.handleDrag,
          onMouseup: $setup.stopDrag
        }, [
          _createElementVNode("img", {
            class: _normalizeClass(["image-wr", { 'image-scale': $setup.props.zoom !== 1 }]),
            src: $props.imageUrl,
            style: _normalizeStyle({ transform: 'scale(' + $setup.props.zoom + ')' })
          }, null, 14, _hoisted_2)
        ], 34),
        _createElementVNode("div", {
          class: _normalizeClass(["buttons-container", [$props.zoom !== 1]])
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              class: "button-close",
              src: _imports_0,
              onClick: $setup.closeModal
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "button button-zoom",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emits('updateZoom', 0.2)))
            }, "+"),
            _createElementVNode("button", {
              class: "button button-zoom",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emits('updateZoom', -0.2)))
            }, "-")
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}